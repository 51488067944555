import logo from './h-m.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
       <br/>
        <h1 class="glitch">
          HHYVEMIND
        </h1>

        <p class="subtitle">
          watch this space..
        </p>
      </header>
      <div class="scanlines"></div>
    </div>
    
  );
}

export default App;
